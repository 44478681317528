"use client";

import { useState } from "react";
import { useFormStatus } from "react-dom";
import Image from "next/image";
import Link from "next/link";

import { signIn, verifyOTP } from "./otp";
import email_svg from "../../public/email-icon.svg";
import { redirect } from "next/navigation";
import { Session } from "@supabase/supabase-js";

// TODO - replace with new registrationbutton component
const RButton = ({ otpSent }) => {
  const { pending } = useFormStatus();

  return (
    <button
      type="submit" // Specify the button type for form submission
      className="btn btn-primary flex-shrink font-medium text-xs sm:text-sm"
      disabled={pending} // Disable the button while loading
    >
      {pending ? (
        <span className="loading loading-spinner text-secondary" />
      ) : otpSent ? (
        "Verify Code"
      ) : (
        "Get Started For Free"
      )}
    </button>
  );
};

const RegistrationButton = ({ session }: { session: Session }) => {
  const [otpSent, setOtpSent] = useState(false);

  const handleOtp = async (formData: FormData) => {
    // setLoading(true);
    if (otpSent) {
      const success = await verifyOTP(formData);
      if (!success) {
        alert(
          "Unable to complete sign up, please retry and verify you entered the correct passcode."
        );
        redirect("/");
      }
      setOtpSent(false);
    } else {
      try {
        const success = await signIn(formData);
        if (!success) {
          alert(
            "Unable to send passcode, please ensure you attempt once every 10 seconds"
          );
          redirect("/");
        }
        setOtpSent(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      {session ? (
        <Link
          href="/deals"
          className="btn btn-primary flex-shrink font-medium text-sm sm:text-md"
        >
          See my savings!
        </Link>
      ) : (
        <div>
          {/* <form className="flex flex-col lg:flex-row gap-2" action={handleOtp}>
            <input
              type="email"
              name="email"
              placeholder="Email address"
              className="input input-bordered w-full lg:max-w-xs"
              required // Added required for form validation
            />
            <input
              name="token"
              type="text"
              placeholder="One Time Pass Code"
              autoComplete="one-time-code"
              className={`input input-bordered w-full lg:max-w-xs ${
                otpSent ? "" : "hidden"
              }`}
            />
            <RButton otpSent={otpSent} />
          </form> */}
          <Link className="btn btn-primary" href="/deals">
            Get Started For Free{" "}
          </Link>
        </div>
      )}
      {otpSent && (
        <div className="bg-info alert alert-info shadow-lg mt-4">
          <div className="flex flex-row gap-2">
            <Image src={email_svg} alt="email icon" width={12} height={12} />
            <span>One time passcode has been sent to your email!</span>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationButton;
