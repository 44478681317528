import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/public/lady_shopping_with_app.svg");
;
import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/public/landing_page.svg");
;
import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/public/screen-tablet.svg");
;
import(/* webpackMode: "eager" */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/public/suggestion.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/mustafaabdelhamid/Documents/git/ValuCents/src/app/registration-button.tsx");
